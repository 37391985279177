import Wallet from '../../components/wallet';
import TaskView from '@/components/task';
export default {
  name: 'AppHeader',
  components: {
    Wallet: Wallet,
    TaskView: TaskView
  },
  methods: {
    goTo: function goTo(to) {
      this.$router.push(to);
    },
    goBack: function goBack() {
      window.history.go(-1);
    }
  }
};