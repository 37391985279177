/** 语言包key值请参考vant3，网址：https://vant-contrib.gitee.io/vant/#/zh-CN/locale **/
export default {
    'zh-CN': {
        'copy-success': '复制成功',
        'title': 'NFT市场',
        'nft.name': '世界和平',
        'nft.desc': '',
        'nft.recommend': '推荐',
        'nft.language': '语言切换',
        'nft.language1': '选择语言'
    },
    'en-US': {
        'copy-success': 'Copy successful!',
        'title': 'NFT Market',
        'nft.name': 'world peace',
        'nft.desc': ' ',
        'nft.recommend': '',
        'nft.language': 'language',
        'nft.language1': 'choose language'
    },
    // ...
}
