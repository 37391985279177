
export default {
    // WBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    // USDT: '0x2b90e061a517db2bbd7e39ef7f733fd234b494ca',
    // NFT: '0x5cC0215F6491CC18EbBDa6e997cb8A1F41Ef7280',
    // Airdrop: '0xdd19021BdcB6459bB23BCcf072d0b93E82B7bdAe'

//测试网
//    WBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
//     USDT: '0x2b90e061a517db2bbd7e39ef7f733fd234b494ca',
//    NFT: '0x17D36111A6fCD355e66dc927A2a9633ebAB6107f',
//    Airdrop: '0x8C040956B0E33B4E03c6476fC9fBf6b502790455'

       WBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    USDT: '0x2b90e061a517db2bbd7e39ef7f733fd234b494ca',
   NFT: '0x21B50A2F4390741A897c5ac63897A7306a88c3eF',
   Airdrop: '0x2338A92b4E428957178EC7544dF93DdB3a7cE473'
}
