import { createRouter, createWebHashHistory,createWebHistory} from "vue-router"
import Container from '../views/layout/Container'

const routes = [{
    path: "/",
    name: "Home",
    redirect: '/home',
    component: Container,
    children: [
        {
            path: '/docs',
            name: 'docs',
            component: () => import('../views/Docs.vue'),
            meta: { title: 'docs', keepAlive: true }
        },
        {
            path: '/home',
            name: 'home',
            component: () => import('../views/Index.vue'),
            meta: { title: 'home', keepAlive: true }
        },
        {
            path: '/mintnft',
            name: 'mintnft',
            component: () => import('../views/mintnft.vue'),
            meta: { title: 'mintnft', keepAlive: true }
        },
        {
            path: '/withdraw',
            name: 'withdraw',
            component: () => import('../views/withdraw.vue'),
            meta: { title: 'withdraw', keepAlive: true }
        },
        {
            path: '/public_chat',
            name: 'public_chat',
            component: () => import('../views/chat/public_chat.vue'),
            meta: { title: 'home', keepAlive: true }
        },
        {
            path: '/group_chat',
            name: 'group_chat',
            component: () => import('../views/chat/group_chat.vue'),
            meta: { title: 'home', keepAlive: true }
        },
        {
            path: '/my',
            name: 'my',
            component: () => import('../views/my.vue'),
            meta: { title: 'my', keepAlive: true }
        },
        {
            path: '/nftdetail',
            name: 'nftdetail',
            component: () => import('../views/nft/details.vue'),
            meta: { title: 'nftdetail', keepAlive: true }
        }
        ]
}]

const router = createRouter({
    mode:"history",
    history: createWebHistory(),
    routes: routes
})
export default router
